<template>
  <div
    class="opacity-75 flex flex-col h-[15vh] fixed bottom-[-15vh] w-full z-[99999999] pointer-events-none animate-[flood_linear_120s_forwards]"
    v-if="isEasterEggVisible"
  >
    <svg
      class="relative w-full h-[40px] lg:h-[15vh] min-h-[40px] lg:min-h-[100px] max-h-[150px] mb-[-7px]"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 24 150 28"
      preserveAspectRatio="none"
      shape-rendering="auto"
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
        />
      </defs>
      <g
        class="[&_use:nth-child(1)]:animate-[waves_ease-in-out_7s_-2s_infinite] [&_use:nth-child(2)]:animate-[waves_ease-in-out_10s_-3s_infinite] [&_use:nth-child(3)]:animate-[waves_ease-in-out_13s_-4s_infinite] [&_use:nth-child(4)]:animate-[waves_ease-in-out_20s_-5s_infinite]"
      >
        <use
          xlink:href="#gentle-wave"
          x="48"
          y="0"
          fill="rgba(28,163,236,0.8)"
        />
        <use
          xlink:href="#gentle-wave"
          x="48"
          y="3"
          fill="rgba(28,163,236,0.5)"
        />
        <use
          xlink:href="#gentle-wave"
          x="48"
          y="5"
          fill="rgba(28,163,236,0.3)"
        />
        <use xlink:href="#gentle-wave" x="48" y="7" fill="rgb(28,163,236)" />
      </g>
    </svg>
    <div style="background-color: rgb(28, 163, 236)" class="grow" />
  </div>
</template>

<script lang="ts" setup>
const { attachEasterEgg, detachEasterEgg, isEasterEggVisible } =
  useTimedEasterEgg('/themen/kultur-sport-und-freizeit/parks-und-rhein/rhein')

onMounted(() => {
  attachEasterEgg()
})

onBeforeUnmount(() => {
  detachEasterEgg()
})
</script>

<style lang="postcss">
@keyframes waves {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

@keyframes flood {
  0% {
    height: 15vh;
  }
  100% {
    height: 115vh;
  }
}
</style>
