<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 512 512"
    class="animate-[shroomGrowth_linear_5s_forwards] origin-[bottom_center]"
  >
    <path
      d="M321.2 446.8a65.2 65.2 0 1 1-130.4 0c0-36 29.2-219 65.2-219s65.2 183 65.2 219z"
      style="fill: #a0815c"
    />
    <path
      d="M230.7 446.8c0-29 19-153.8 45.2-200.8-6.3-11.3-13-18.1-19.9-18.1-36 0-65.2 183-65.2 219a65.2 65.2 0 0 0 85.1 62 65.2 65.2 0 0 1-45.2-62z"
      style="fill: #806749"
    />
    <path
      d="M261.4 333.9c14.3 0 28.5-.5 42.5-1.5-11.9-53.5-29-104.5-48-104.5-18.9 0-36 50.5-47.8 103.7 16.8 1.4 34.6 2.3 53.3 2.3z"
      style="fill: #806749"
    />
    <path
      d="M462.8 236.3c-.4-2.4-10.2-57.9-38.5-114-20.4-40.4-45.4-71.2-74.1-91.8A156.7 156.7 0 0 0 256 2c-16.3 0-32 .2-47 4.8-35.4 10.8-66.8 34.3-93.2 69.7-10.1 13.5-17.4 30.9-26 47.8a454.9 454.9 0 0 0-30.4 70.3c-7 23.7-10 40.3-10.2 41.6a31.3 31.3 0 0 0 19.6 34.6c3.4 1.3 83.8 31.6 192.6 31.6 64.3 0 125.4-10.6 181.5-31.5a31.3 31.3 0 0 0 20-34.7z"
      style="fill: #ff3f62"
    />
    <path
      d="M289.4 49c49.9 0 93.8 21.7 129.6 63.3-19.4-35.6-42.5-63-68.8-81.8A156.7 156.7 0 0 0 256 2c-16.5 0-32.4.3-47.6 5-35 10.9-66.3 34.2-92.5 69.2-10.1 13.6-17.5 30-26.1 47a460.7 460.7 0 0 0-30 70 401.4 401.4 0 0 0-10.6 43 31.3 31.3 0 0 0 19.6 34.6c.8.3 6.3 2.3 15.6 5.3 4-18.4 14.8-61.4 36.7-104.8C161.6 91.4 219.8 49 289.4 49z"
      style="fill: #d80027"
    />
    <circle cx="295.8" cy="188.1" r="71.8" style="fill: #fff" />
    <path
      d="M114.5 220A71.8 71.8 0 0 0 116 76.2a309 309 0 0 0-28.2 46 440 440 0 0 0-28.2 72 71.7 71.7 0 0 0 55 25.6zM279 92.8a71.8 71.8 0 0 0 71.2-62.3A158.6 158.6 0 0 0 256 0c-16.5 0-32.3 2.4-47.5 7A71.8 71.8 0 0 0 279 92.9z"
      style="fill: #fff"
    />
    <path
      d="M116 76.3a309.1 309.1 0 0 0-28.3 46 440 440 0 0 0-28.2 72 71.8 71.8 0 0 0 41.5 24.3c5.3-14.8 12-31 20.1-47.2 14-27.8 30.3-51 48.3-69.6A71.7 71.7 0 0 0 116 76.3zM256 0c-16.5 0-32.3 2.4-47.5 7a72.1 72.1 0 0 0 13 57c21-9.9 43.8-15 68-15 17.9 0 35 2.9 51.4 8.4 4.8-8 8-17.2 9.3-27A158.6 158.6 0 0 0 256 0z"
      style="fill: #ffa5b5"
    />
    <path
      d="M419 249a7.8 7.8 0 0 1-7.8-7.9c0-7.9-.9-16.4-2.5-25.4a7.8 7.8 0 0 1 15.4-2.8c1.8 9.9 2.8 19.4 2.8 28.2 0 4.4-3.6 7.9-7.9 7.9zM409 193.3a7.8 7.8 0 0 1-7.5-5.4 309.9 309.9 0 0 0-26.4-58.4 7.8 7.8 0 0 1 13.3-8.2 320 320 0 0 1 28 61.7 7.8 7.8 0 0 1-7.4 10.3z"
      style="fill: #d80027"
    />
    <path
      d="M271.8 484.6a7.8 7.8 0 0 1-3.6-14.8 26 26 0 0 0 13.8-23c0-4.4-.8-13.1-2-23.8a7.8 7.8 0 1 1 15.5-2c1.4 11.6 2.2 20.7 2.2 25.8 0 15.5-8.5 29.6-22.2 36.8-1.2.7-2.4 1-3.7 1z"
      style="fill: #806749"
    />
  </svg>
</template>

<style lang="postcss">
@keyframes shroomGrowth {
  0% {
    transform: scale(0) rotate(var(--rotation));
  }
  100% {
    transform: scale(1) rotate(var(--rotation));
  }
}
</style>
