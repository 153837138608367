<template>
  <div
    v-if="isEasterEggVisible"
    class="absolute inset-0 z-[99999999] overflow-hidden pointer-events-none"
  >
    <EasterEggMushroomFieldMushroom
      v-for="(shroom, i) in shrooms"
      :key="`shroom_${i}`"
      class="absolute"
      :style="`
        top: ${shroom.y}px;
        left: ${shroom.x}px;
        width: ${shroom.sizeCoefficient * 512 * 0.5}px;
        height: ${shroom.sizeCoefficient * 512 * 0.5}px;
        --rotation: ${shroom.rotation}deg;
      `"
    />
  </div>
</template>

<script lang="ts" setup>
const { attachEasterEgg, detachEasterEgg, isEasterEggVisible } =
  useTimedEasterEgg(
    '/themen/gesundheit/wo-kann-man-basel-pilze-kontrollieren-lassen',
  )

onMounted(() => {
  attachEasterEgg()
})

onBeforeUnmount(() => {
  detachEasterEgg()
})

let interval: ReturnType<typeof setInterval> | undefined = undefined

type Shroom = {
  x: number
  y: number
  sizeCoefficient: number
  rotation: number
}

const shrooms = ref<Shroom[]>([])

onMounted(() => {
  attachEasterEgg()
})

onBeforeUnmount(() => {
  detachEasterEgg()
  clearInterval(interval)
})

watch(isEasterEggVisible, (newValue) => {
  if (!newValue) {
    clearInterval(interval)
  } else {
    const pageWidth = document.body.scrollWidth - 512 * 0.5
    const pageHeight = document.body.scrollHeight - 512 * 0.5

    interval = setInterval(() => {
      if (isEasterEggVisible.value) {
        shrooms.value.push({
          x: Math.random() * pageWidth,
          y: Math.random() * pageHeight,
          sizeCoefficient: Math.max(0.5, Math.random()),
          rotation: Math.random() * 60 - 30,
        })
      }
    }, 1000)
  }
})
</script>

<style lang="postcss">
#nuxt-root {
  @apply relative;
}
</style>
