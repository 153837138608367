let timer: ReturnType<typeof setTimeout> | null = null
const EASTER_EGG_TIMER_DURATION_MINUTES = 2

const EASTER_EGG_TIMER_RESET_INTERACTIONS = [
  'mousemove',
  'scroll',
  'keydown',
  'keyup',
  'touchstart',
  'touchmove',
  'mousedown',
  'mouseup',
]

const timerPassed = ref(false)

function resetTimer() {
  if (timer !== null) {
    clearTimeout(timer)
  }

  timer = setTimeout(
    () => {
      timerPassed.value = true
    },
    EASTER_EGG_TIMER_DURATION_MINUTES * 60 * 1000,
  )
}

function attachEasterEgg() {
  EASTER_EGG_TIMER_RESET_INTERACTIONS.forEach((e) => {
    window.addEventListener(e, resetTimer)
  })
}

function detachEasterEgg() {
  EASTER_EGG_TIMER_RESET_INTERACTIONS.forEach((e) => {
    window.removeEventListener(e, resetTimer)
  })
}

export default function (path: string) {
  const route = useRoute()

  const shouldEasterEggBeVisible = ref(route.fullPath.startsWith(path))

  const isEasterEggVisible = computed(() => {
    return shouldEasterEggBeVisible.value && timerPassed.value
  })

  watch(
    () => route.fullPath,
    (newRoute) => {
      shouldEasterEggBeVisible.value = newRoute.startsWith(path)
      timerPassed.value = false

      resetTimer()
    },
  )

  resetTimer()

  return {
    attachEasterEgg,
    detachEasterEgg,
    isEasterEggVisible,
  }
}
